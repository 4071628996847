


















































import Vue from "vue"
import { mapGetters } from "vuex"
import { uniqBy, last, get } from "lodash"
import { UsersActions } from "@/services/game.service"
import MissionCollection from "@shared/MissionCollection"
import Mission from "@shared/enums/Mission"

import quickState from "@/components/drawers/quick"
import { PannelNames } from "@/components/ActivityMenuPanels/ActivityMenuPanels.consts"

import { WeveButton, WeveSelectField } from "@weve/ui"
import BreakoutMixin from "@/components/BreakoutTypeDialog/BreakoutMixin"
import useMissions from "@/use/useMissions"

export default Vue.extend({
  name: "PollSection",
  components: {
    WeveButton,
    WeveSelectField
  },
  mixins: [BreakoutMixin],
  data() {
    return {
      pollMissionID: null
    }
  },
  setup() {
    const { missions: m } = useMissions()
    return { m }
  },
  computed: {
    ...mapGetters("auth", ["isModerator"]),
    ...mapGetters(["orgID", "actualGameID", "getCurrentMode", "plays"]),
    hostButtonTitle() {
      return this.$_BreakoutMixin_hasBreakouts
        ? "UNDO BREAKOUT"
        : "CREATE POLL GROUPS"
    },
    moderatorButtonTitle() {
      if (this.$_BreakoutMixin_hasBreakouts) {
        return "UNDO BREAKOUT"
      }

      if (this.isPrevPoll) {
        return "CREATE POLL GROUPS"
      }

      return "CREATE POLL"
    },
    isButtonDisabled() {
      return (
        this.$_BreakoutMixin_processing ||
        (!this.numOfPlayers && !this.$_BreakoutMixin_hasBreakouts)
      )
    },
    isSelectDisabled() {
      return !this.pollMissions.length || this.$_BreakoutMixin_hasBreakouts
    },
    processing() {
      return (
        this.$_BreakoutMixin_currentType ===
          this.$_BreakoutMixin_type.BY_POLL && this.$_BreakoutMixin_processing
      )
    },
    isPrevPoll() {
      const prevMissionID = get(this.prevMission, "id")
      const lastPollMissionID = get(this.lastPollMission, "value")

      return (
        prevMissionID &&
        lastPollMissionID &&
        prevMissionID === lastPollMissionID
      )
    },
    missions() {
      return MissionCollection.normalize(this.m)
    },
    currentMissionIndex() {
      return parseInt(
        this.missions.findIndex(
          mission => mission.id === this.currentMission.id
        ) || 0
      )
    },
    prevMission() {
      return this.missions[this.currentMissionIndex - 1]
    },
    pollMissions() {
      return (
        uniqBy(
          Object.values(this.plays ?? {}).filter(
            // @ts-ignore IGNORE
            play => play.behavior === Mission.Poll
          ),
          "missionID"
        )
          // @ts-ignore IGNORE
          .sort((a, b) => a.missionID - b.missionID)
          .map(({ missionID, mission }) => ({
            value: missionID,
            label: mission
          }))
      )
    },
    lastPollMission() {
      return last(this.pollMissions)
    }
  },

  watch: {
    $_BreakoutMixin_state: {
      handler(value) {
        if (value?.type === this.$_BreakoutMixin_type.BY_POLL && value?.size) {
          this.pollMissionID = value?.size
        }
      },
      immediate: true
    }
  },

  methods: {
    hostClick() {
      if (this.$_BreakoutMixin_hasBreakouts) {
        this.$_BreakoutMixin_reset()
      } else {
        this.breakoutByPoll(this.pollMissionID)
      }
    },
    moderatorClick() {
      if (this.$_BreakoutMixin_hasBreakouts) {
        this.$_BreakoutMixin_reset()
      } else if (this.isPrevPoll) {
        this.breakoutByPoll(this.prevMission.id)
      } else {
        this.createPoll()
      }
    },
    breakoutByPoll(missionID) {
      this.$_BreakoutMixin_process(async () => {
        await UsersActions.breakoutByPoll({
          gameID: this.actualGameID,
          orgID: this.orgID,
          missionID
        })

        await this.$_BreakoutMixin_updateState({
          breakout: {
            type: this.$_BreakoutMixin_type.BY_POLL,
            pollID: missionID
          }
        })
      })
    },
    createPoll() {
      // @ts-ignore IGNORE
      quickState.value = PannelNames.Poll
      this.$_BreakoutMixin_visibility = false
    }
  }
})
