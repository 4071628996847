






































import Vue from "vue"
import { mapGetters } from "vuex"
import { UsersActions } from "@/services/game.service"

import { WeveButton, WeveSpinbutton, WeveCheckbox } from "@weve/ui"
import BreakoutMixin from "./BreakoutMixin"

export default Vue.extend({
  name: "TeamsSection",
  components: {
    WeveButton,
    WeveCheckbox,
    WeveSpinbutton
  },
  mixins: [BreakoutMixin],
  data() {
    return {
      selfSelect: false,
      numOfPlayers: 0
    }
  },
  computed: {
    ...mapGetters(["orgID", "actualGameID", "getCurrentMode"]),
    isSelfSelect: {
      get() {
        return (
          this.$_BreakoutMixin_currentType ===
            this.$_BreakoutMixin_type.SELF_SELECT || this.selfSelect
        )
      },
      set(value) {
        this.selfSelect = value
      }
    },
    buttonTitle() {
      if (this.$_BreakoutMixin_hasBreakouts) {
        return "UNDO BREAKOUT"
      }

      if (this.isSelfSelect) {
        return "SELF SELECT"
      }

      return "CREATE TEAMS"
    },
    isButtonDisabled() {
      return (
        this.$_BreakoutMixin_processing ||
        (!this.numOfPlayers && !this.$_BreakoutMixin_hasBreakouts)
      )
    },
    processing() {
      switch (this.$_BreakoutMixin_currentType) {
        case this.$_BreakoutMixin_type.BY_SIZE:
        case this.$_BreakoutMixin_type.SELF_SELECT:
          return this.$_BreakoutMixin_processing

        default:
          return false
      }
    }
  },
  watch: {
    $_BreakoutMixin_state: {
      handler(value) {
        if (value?.size) {
          this.numOfPlayers = value?.size
        }
      },
      immediate: true
    }
  },
  methods: {
    handleClick() {
      if (this.$_BreakoutMixin_hasBreakouts) {
        this.$_BreakoutMixin_reset()
      } else if (this.isSelfSelect) {
        this.breakoutSelfSelect()
      } else {
        this.breakoutByTeams()
      }
    },
    breakoutByTeams() {
      this.$_BreakoutMixin_process(async () => {
        await UsersActions.breakoutByTeam({
          gameID: this.actualGameID,
          orgID: this.orgID,
          size: this.numOfPlayers
        })

        await this.$_BreakoutMixin_updateState({
          breakout: {
            type: this.$_BreakoutMixin_type.BY_SIZE,
            size: this.numOfPlayers
          }
        })
      })
    },

    async breakoutSelfSelect() {
      await this.$_BreakoutMixin_process(async () => {
        await UsersActions.breakoutSelfSelect({
          gameID: this.actualGameID,
          orgID: this.orgID
        })

        await this.$_BreakoutMixin_updateState({
          breakout: {
            type: this.$_BreakoutMixin_type.SELF_SELECT,
            size: this.numOfPlayers
          },
          started: false
        })
      })
      this.$_BreakoutMixin_visibility = false
    }
  }
})
