var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { staticClass: "mx-2", attrs: { flex: "", column: "", xs4: "" } },
    [
      _c(
        "h2",
        { staticClass: "white--text mb-4", attrs: { align: "center" } },
        [_vm._v("Teams")]
      ),
      _c("WeveSpinbutton", {
        attrs: {
          label: !_vm.isSelfSelect
            ? "Break into teams of"
            : "Max players per team",
          hint: !_vm.isSelfSelect
            ? "Participants will be randomly placed"
            : "_",
          disabled: _vm.$_BreakoutMixin_hasBreakouts,
        },
        model: {
          value: _vm.numOfPlayers,
          callback: function ($$v) {
            _vm.numOfPlayers = $$v
          },
          expression: "numOfPlayers",
        },
      }),
      _c("WeveCheckbox", {
        staticClass: "my-2",
        attrs: {
          label: "Self select",
          disabled: _vm.$_BreakoutMixin_hasBreakouts,
        },
        model: {
          value: _vm.isSelfSelect,
          callback: function ($$v) {
            _vm.isSelfSelect = $$v
          },
          expression: "isSelfSelect",
        },
      }),
      _c("div", { staticStyle: { "font-size": "14px" } }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.isSelfSelect
                ? "_"
                : "Participants will breakout into temporary teams"
            ) +
            " "
        ),
      ]),
      _c(
        "WeveButton",
        {
          staticClass: "mt-auto",
          attrs: {
            block: "",
            size: "sm",
            variant: "accent",
            disabled: _vm.isButtonDisabled,
            loading: _vm.processing,
          },
          on: { click: _vm.handleClick },
        },
        [_vm._v(" " + _vm._s(_vm.buttonTitle) + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }