import { BreakoutType } from "@shared/enums"
import { UsersActions } from "@/services/game.service"
import { db } from "@/firebase"
import Mode from "@shared/enums/Mode"
import Vue from "vue"

const state = Vue.observable({
  processing: false,
  visibility: false
})

export default {
  computed: {
    $_BreakoutMixin_hasBreakouts() {
      const teams = this.$store.getters.chats || {}
      return Object.values(teams).some(
        ({ deleted, breakout }) => breakout && !deleted
      )
    },
    $_BreakoutMixin_isHuddle() {
      return this.$store.getters?.getCurrentMode === Mode.Huddle
    },
    $_BreakoutMixin_type() {
      return BreakoutType
    },
    $_BreakoutMixin_state() {
      return this.$store.getters?.game?.breakout
    },
    $_BreakoutMixin_currentType() {
      return this.$_BreakoutMixin_state?.type
    },
    $_BreakoutMixin_processing() {
      return state.processing
    },
    $_BreakoutMixin_visibility: {
      get() {
        return state.visibility
      },
      set(value) {
        state.visibility = value
      }
    }
  },
  methods: {
    async $_BreakoutMixin_updateState(payload) {
      await this.$store.dispatch("Games/updateGameAny", {
        theKey: this.$store.getters.game.id,
        ...payload
      })
    },
    async $_BreakoutMixin_process(callback) {
      state.processing = true
      await callback()
      state.processing = false
    },
    async $_BreakoutMixin_reset() {
      this.$_BreakoutMixin_process(async () => {
        await UsersActions.breakoutUndo({
          gameID: this.$store.getters?.actualGameID,
          orgID: this.$store.getters?.orgID
        })
        await this.$_BreakoutMixin_updateState({ breakout: null })
      })
    },
    $_BreakoutMixin_toggle() {
      const value = this.$_BreakoutMixin_isHuddle ? Mode.Play : Mode.Huddle
      const { orgID, gameID } = this.$store.getters
      db.auxiliary()
        .ref(`org/${orgID}/game/${gameID}/gameStatus/currentMode`)
        .set(value)
    }
  }
}
