var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { staticClass: "mx-2", attrs: { flex: "", column: "", xs4: "" } },
    [
      _c(
        "h2",
        { staticClass: "white--text mb-4", attrs: { align: "center" } },
        [_vm._v("Huddle Mode")]
      ),
      _c(
        "div",
        { staticClass: "w-full mt-auto" },
        [
          _c(
            "div",
            {
              staticClass: "text-center pa-2",
              staticStyle: { "font-size": "14px", "margin-bottom": "24px" },
            },
            [_vm._v(" Participants will go into Huddle Mode ")]
          ),
          _c(
            "WeveButton",
            {
              attrs: { block: "", size: "sm", variant: "accent" },
              on: { click: _vm.$_BreakoutMixin_toggle },
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$_BreakoutMixin_isHuddle
                      ? "EXIT FROM HUDDLE"
                      : "ENTER HUDDLE MODE"
                  ) +
                  " "
              ),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }