<template>
  <WeveDialog
    v-model="$_BreakoutMixin_visibility"
    dialogClass="breakout-dialog"
    title="Breakouts Type"
  >
    <h1 class="breakout-dialog__title white--text mb-4">
      CHOOSE BREAKOUTS TYPE
    </h1>
    <v-flex row style="height: 280px">
      <HuddleSection />

      <TeamsSection />

      <PollSection />
    </v-flex>
  </WeveDialog>
</template>

<script>
import { WeveDialog } from "@weve/ui"

import HuddleSection from "@/components/BreakoutTypeDialog/HuddleSection"
import TeamsSection from "@/components/BreakoutTypeDialog/TeamsSection"
import PollSection from "@/components/BreakoutTypeDialog/PollSection"

import BreakoutMixin from "@/components/BreakoutTypeDialog/BreakoutMixin"

export default {
  name: "BreakoutTypeDialog",
  components: {
    HuddleSection,
    TeamsSection,
    PollSection,
    WeveDialog
  },
  mixins: [BreakoutMixin]
}
</script>

<style lang="scss">
.breakout-dialog {
  font-family: "Sofia Pro", sans-serif;
  color: #9aa1ad;
  max-width: 1092px !important;

  &__title {
    font-family: Right Grotesk, serif;
    font-weight: 900;
    font-size: 32px;
  }

  .modal-header {
    padding: 5px 20px;
    border-bottom: none;
    z-index: 99;

    .weve-dialog__title {
      display: none;
    }
  }

  .modal-content {
    height: 396px;
  }
}
</style>
