var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { staticClass: "mx-2", attrs: { flex: "", column: "", xs4: "" } },
    [
      _c(
        "h2",
        { staticClass: "white--text mb-4", attrs: { align: "center" } },
        [_vm._v("Poll")]
      ),
      !_vm.isModerator
        ? _c("WeveSelectField", {
            staticStyle: { "margin-top": "3px" },
            attrs: {
              label: "Breakout by poll response",
              disabled:
                !_vm.pollMissions.length || _vm.$_BreakoutMixin_hasBreakouts,
              options: _vm.pollMissions,
            },
            model: {
              value: _vm.pollMissionID,
              callback: function ($$v) {
                _vm.pollMissionID = $$v
              },
              expression: "pollMissionID",
            },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "w-full mt-auto" },
        [
          _c(
            "div",
            { staticStyle: { "font-size": "14px", "margin-bottom": "24px" } },
            [
              _vm._v(
                " Participant groups will be determined by their poll response "
              ),
            ]
          ),
          _vm.isModerator
            ? _c(
                "WeveButton",
                {
                  attrs: {
                    block: "",
                    size: "sm",
                    variant: "accent",
                    loading: _vm.processing,
                  },
                  on: {
                    click: function ($event) {
                      _vm.$_BreakoutMixin_hasBreakouts
                        ? _vm.resetBreakoutTeams()
                        : _vm.isPrevPoll
                        ? _vm.breakoutByPoll(_vm.prevMission.id)
                        : _vm.createPoll()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.moderatorButtonTitle) + " ")]
              )
            : _c(
                "WeveButton",
                {
                  attrs: {
                    disabled:
                      !_vm.pollMissions.length ||
                      _vm.$_BreakoutMixin_processing,
                    block: "",
                    size: "sm",
                    variant: "accent",
                    loading: _vm.processing,
                  },
                  on: { click: _vm.hostClick },
                },
                [_vm._v(" " + _vm._s(_vm.hostButtonTitle) + " ")]
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }