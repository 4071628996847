var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "WeveDialog",
    {
      attrs: { dialogClass: "breakout-dialog", title: "Breakouts Type" },
      model: {
        value: _vm.$_BreakoutMixin_visibility,
        callback: function ($$v) {
          _vm.$_BreakoutMixin_visibility = $$v
        },
        expression: "$_BreakoutMixin_visibility",
      },
    },
    [
      _c("h1", { staticClass: "breakout-dialog__title white--text mb-4" }, [
        _vm._v(" CHOOSE BREAKOUTS TYPE "),
      ]),
      _c(
        "v-flex",
        { staticStyle: { height: "280px" }, attrs: { row: "" } },
        [_c("HuddleSection"), _c("TeamsSection"), _c("PollSection")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }