
























import Vue from "vue"
import BreakoutMixin from "@/components/BreakoutTypeDialog/BreakoutMixin"

import { WeveButton } from "@weve/ui"

export default Vue.extend({
  name: "HuddleSection",
  components: {
    WeveButton
  },
  mixins: [BreakoutMixin]
})
