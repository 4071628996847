export const answerTrmplate = () => ({
  placeholder: "Type here...",
  value: ""
})

export const topicExamples = ["Mercury", "Venus", "Earth", "Mars", "Jupiter"]

export const answerTrmplates = (items = topicExamples) =>
  items.map(item => ({
    placeholder: `e.g. ${item}`,
    value: ""
  }))

export const PannelNames = {
  AddActivities: "AddActivities",
  Categories: "Categories",
  Poll: "Poll",
  MultipleChoice: "MultipleChoice"
}
